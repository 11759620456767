body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: 'Roboto', sans-serif;
    color: #383838;
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ------------ BUTTON'S CUSTOMIZED STYLING TO COMPLEMENT GOLDBACH COLOR SCHEME ------------- */
.btn {
    border-radius: 10px;
}

.addUser {
    padding: 15px;
    color: white;
    font-size: 17px;
}

.btn-success {
    background-color: #619139;
    border: 1px solid #619139;
}

.btn-success:hover {
    background-color: #89B664;
    border: 1px solid #619139;
}

.btn-info {
    background-color: #3fb5c9;
    border: 1px solid #60C3D4;
    color: white;
}

.btn-info:hover {
    background-color: #75cddc;
    border: 1px solid #60C3D4;
    color: white;
}

.btn-outline-success {
    background-color: #DCF2F6;
    color: #3fb5c9;
    border-radius: 30px;
    border: 0;
}

.btn-outline-success:hover {
    background-color: #75cddc;
    border: 0;
    color: white;
}

.btn-outline-success:active {
    background-color: #64b2bf !important;
    border: none !important;
    outline: none !important;
}

.btn-outline-success:focus {
    background-color: #75cddc;
}

.btn-edit {
    background-color: #89bd40;
    border: 1px solid #89bd40;
    color: white;
}

.btn-edit:hover {
    background-color: #a0c76a;
    border: 1px solid #89bd40;
    color: white;
}

.btn-outline-secondary {
    background-color: transparent;
    color: #7194c5;
    border: 1px solid #7194c5;
    font-size: 16px;
    padding: 15px 70px;
}

.btn-outline-secondary:hover {
    background-color: #7194c5;
    border: 1px solid #7194c5;
    color: white;
}

/* ------------ FORM STYLING ------------- */
.form-control {
    border-radius: 10px;
    height: 50px;
    border: 1px solid #C8C8C8;
    background-color: #f1f2f7;
}

.editUSerBtn {
    font-size: 14px;
    font-weight: 500;
}

.form-check-label {
    text-transform: none;
    color: #383838;
    font-weight: 400;
}

/* ------------ MODAL STYLING ------------- */
.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
}

@media (max-width: 768px) {
    .modal-dialog {
        min-height: calc(100vh - 20px);
    }
}

.modal-content {
    border-radius: 10px;
}

.modal-header,
.modal-footer {
    border: 0;
}

.modal-footer {
    justify-content: flex-start !important;
    padding-left: 0 !important;
}

.modal-title {
    font-weight: 700;
}

/* ------------ ICONS' STYLING ------------- */
.addIcon {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
}