label {
    font-size: 14px;
    color: #afb2bb;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 3px;
}

input[type="checkbox"] {
    width: 30px;
    display: inline;
    /*position: relative;*/
    margin-left: 0;
    top: 12px;
    float: left;
    margin-top: 6px;
    border: 1px solid #C8C8C8;
    background-color: #f1f2f7;
}

.save-btn {
    float: right;
}

.btn-link {
    outline: none;
    color: #878787;
}

*:hover {
    text-decoration: none !important
}

.card-header button.btn:hover {
    background: #8D1825;
    color: white;
}

.card-header button.btn {
    width: 100%;
    color: #000;
}

.card-header {
    padding: 0 !important;
}

.addUser {
    width: 200px;
    padding: 5px;
    /*position:absolute;
    bottom:1%;*/
}

.accordion {
    flex: 50%;
}

.form-check {
    background-color: #f1f2f7;
    border-radius: 10px;
    margin: 45px 0;
}

.form-check .textField {
    margin-bottom: 0;
}

.cancelAddAccess {
    position: absolute;
    right: 15px;
    top: -40px;
}

.addPerm, .saveUser {
    width: 100%;
}