
html, body {
    background: #F7F7F9;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
    overflow-x: hidden;
}

.App {
    text-align: center;
    background: #282c34;
    color: white;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    display: block;
    float: left;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loader {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
}

.error {
    position: absolute;
    color: red;
    font-size: .7rem;
    margin-top: 2px;
}

#root {
    background: #F7F7F9;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.MainContent {
    background-color: #fff;
}

.Sidebar {
    /*background-color: white;*/
    background-color: #f9faff;
    height: 100%;
    padding: 0;
    border-right: solid 1px #dbe1f1;
}
.AddUserColumn {
    padding: 5%;
}

.ColumnTitle, .ColumnTitleMain {
    font-weight: 700;
    font-size: 20px;
}

.ColumnTitle {
    padding: 3% 0 3% 0;
}

.ColumnTitleMain {
    padding: 3% 0 1% 0;
}


