.userBox {
    width: 100% !important;
    display: block !important; /* Force block display */
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #C8C8C8;
    box-shadow: 0 1px 2px rgba(192, 192, 192, 0.2), 0 1px 2px rgba(192, 192, 192, 0.3);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    -webkit-animation: fadein 2s;
    -moz-animation: fadein 2s;
    -o-animation: fadein 2s;
    animation: fadein 2s;
    margin-bottom: 10px;
    max-width: none !important; /* Remove max-width restriction */
    flex: none !important; /* Remove flex property */
}

/* Remove any parent container flex properties */
.userBox-container {
    display: block !important;
    width: 100%;
}

.userBox p {
    margin-bottom: 3px;
}

.userName {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 700;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.userAdmin {
    font-size: 12px;
    text-transform: capitalize;
    background: #f0f0f0;
    padding: 2px 8px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 10px;
}

.userEmail {
    font-size: 14px;
    color: #878787;
}

.userValid {
    font-size: 12px;
    color: #878787;
    font-weight: bold;
}

.editPerm {
    font-size: 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.inactive {
    opacity: 0.5;
}

.addPermission-btn, .editUser {
    width: 100%;
    margin-bottom: 10px;
    display: block;
}

.firstUser::before {
    content: "Your Account";
    color: #383838;
    letter-spacing: 1px;
    position: absolute;
    left: 0;
    top: -40px;
    font-weight: 700;
    font-size: 20px;
}

.firstUser {
    margin-top: 50px;
    background: #8d1825;
    color: white;
}

.firstUser p {
    color: white;
}

/* Animation keyframes remain the same */
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Permissions container */
.permissions-container {
    display: block;
    width: 100%;
    margin-top: 10px;
}

/* User content when expanded */
.user-content {
    display: block;
    padding: 15px;
    border-top: 1px solid #eee;
}