header {
    background: #8D1825;
    font-size: 24px;
    text-align: center;
    color: #fff
}

.logo img {
    max-width: 150px;
    float: left;
}

.appName {
    font-size: 20px;
}